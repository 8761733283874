/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {
    object, string, shape,
} from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { RenderChatAsLink } from '../../../../../MultiBrandChatController/AigoChatWidgetWrapperV2/popOpenChat';

const styles = (theme) => ({
    mainChooseBanner: {
        textAlign: 'center',
        padding: '10px 0',
    },
    chooseBannerText: {
        fontSize: 24,
        [theme.breakpoints.down(768)]: {
            width: 210,
            margin: '0 auto',
        },
    },
    bannerText: {
        fontSize: 15,
        [theme.breakpoints.down(768)]: {
            padding: '0 30px',
        },
        '& p': {
            margin: '5px 0',
        },
    },

});
const ChooseBlock = ({
    classes, chooseBlock,
}) => {
    if (!chooseBlock) {
        return null;
    }

    const chooseHeaderText = chooseBlock?.header || '';
    const chooseCopyText = chooseBlock?.copy || '';

    // for styling backgroundColor ChooseBanner
    const chooseBannerBlockStyle = {
        backgroundColor: chooseBlock?.background_color || '#D3D3D3',
        color: chooseBlock?.text_color || '#000000',
    };
    return (
        <Grid item xs={12}>
            <div style={chooseBannerBlockStyle} className={classes.mainChooseBanner}>
                <div className={classes.chooseBannerText}>
                    {chooseHeaderText}
                </div>
                <div>
                    <ReactMarkdown
                        className={classes.bannerText}
                        source={chooseCopyText}
                        renderers={{
                            text: ({ value }) => {
                                if (typeof value === 'string' && value?.toLowerCase()?.includes('chat')) {
                                    return <RenderChatAsLink value={value} />;
                                }
                                return value;
                            },
                        }}
                    />
                </div>
            </div>
        </Grid>
    );
};
ChooseBlock.propTypes = {
    classes: object,
    chooseBlock: shape({
        background_color: string,
        copy: string,
        header: string,
        text_color: string,
    }),
};
ChooseBlock.defaultProps = {
    classes: {},
    chooseBlock: {},
};
export default (withStyles(styles)(ChooseBlock));
