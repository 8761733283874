/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ReactMarkdown from 'react-markdown';
import { object } from 'prop-types';
import { RenderChatAsLink } from '../../../../MultiBrandChatController/AigoChatWidgetWrapperV2/popOpenChat';

const styles = (theme) => ({
    footerBody: {
        paddingLeft: '10px',
        paddingRight: '10px',
        '& ul, & ol': {
            padding: 'revert',
            marginBottom: '15px',
        },
        '& h2': {
            color: theme.palette.cms?.primary || theme.palette.colorPrimary,
            fontFamily: theme.typography.fontFamily,
            fontSize: '16px',
            margin: '0',
            marginBottom: '10px',
        },
        '& p': {
            marginBottom: '15px',
            color: theme.palette.colorNeutral70,
        },
        '& a': {
            color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        },
        '& hr': {
            margin: 0,
            height: '1px',
            borderWidth: 0,
            backgroundColor: theme.palette.colorNeutral40,
        },
    },

});

const GraphqlDisclaimer = ({ classes, disclaimerBlock }) => {
    const languageAttribute = (disclaimerBlock?.language_attribute && disclaimerBlock?.language_attribute !== 'en') ? disclaimerBlock?.language_attribute : '';
    if (disclaimerBlock?.copy) {
        const renderers = {
            text: ({ value }) => {
                if (typeof value === 'string' && value?.toLowerCase()?.includes('chat')) {
                    return <RenderChatAsLink value={value} />;
                }
                return value;
            },
        };
        return (
            <div id="disclaimer" className={classes.footerBody} lang={languageAttribute}>
                {
                    disclaimerBlock.copy && (
                        <ReactMarkdown
                            renderers={renderers}
                            source={disclaimerBlock.copy}
                            skipHtml
                        />
                    )
                }
            </div>
        );
    }
    return null;
};

GraphqlDisclaimer.propTypes = {
    disclaimerBlock: object,
    classes: object.isRequired,
};
GraphqlDisclaimer.defaultProps = {
    disclaimerBlock: {},
};
export default withStyles(styles)(GraphqlDisclaimer);
