/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { shape, string, arrayOf } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import ReactMarkdown from 'react-markdown';
import { useUIDSeed } from 'react-uid';
import FaqSeoSchema from '../../../../../helpers/Seo/FaqSeoSchema';

const useStyles = makeStyles((theme) => ({
    titleWrapper: {
        display: 'flex',
        alignItems: 'center',
        margin: '0 0 20px 0',
        '& svg': {
            height: '20px',
            width: '20px',
        },
    },
    title: {
        color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        fontFamily: theme.typography.fontFamily,
        fontSize: '17px',
        margin: '0 0 0 7px',
    },
    question: {
        fontSize: '16px',
        color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        marginLeft: 28,
        marginBottom: '10px',
    },
    markdown: {
        marginLeft: 28,
        marginBottom: '15px',
        '& p': {
            color: theme.palette.colorNeutral70,
        },
        '& a': {
            color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        },
    },
}));

const FaqBlock = ({ faqBlock }) => {
    const classes = useStyles();
    const faqData =  faqBlock?.faq || {};
    const faqArray = faqData?.faqs || faqBlock?.faqs || [];
    const faqHeader = faqData?.title || faqBlock?.title ||  '';
    const languageAttribute = faqData?.language_attribute || faqBlock?.language_attribute || 'en';
    const seed = useUIDSeed();

    if (faqArray.length) {
        return (
            <>
                <FaqSeoSchema faqArray={faqArray} />
                {faqHeader && (
                    <div className={classes.titleWrapper}>
                        <QuestionAnswerIcon />
                        <h2 className={classes.title}>{faqHeader}</h2>
                    </div>
                )}
                {faqArray.map((data) => (
                    <div key={seed(data)} lang={languageAttribute}>
                        <h3 className={classes.question}>{data.question}</h3>
                        <ReactMarkdown
                            source={data.answer}
                            skipHtml
                            className={classes.markdown}
                        />
                    </div>
                ))}
            </>
        );
    }
    return null;
};

FaqBlock.propTypes = {
    faqBlock: shape({
        faq: shape({
            faqs: arrayOf(shape({
                question: string,
                answer: string,
            })).isRequired,
        }),
        title: string,
    }).isRequired,
};

export default FaqBlock;
